import Swiper, { Navigation, Pagination } from 'swiper';

document.addEventListener('DOMContentLoaded', () => {
    Swiper.use([Navigation, Pagination]);
    const reviewsSlider = new Swiper('.reviews__slider', {
        modules: [Navigation, Pagination],
        loop: false,
        slidesPerView: 1,
        autoHeight: false,
        pagination: {
            el: ".swiper-pagination-reviews",
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next-reviews',
            prevEl: '.swiper-button-prev-reviews',
        },
    });

});