
import '../../inc/scss/swiper/swiper.scss';
import '../../inc/scss/swiper/swiper-font.scss';


import '../../components/why-choose/why-choose';
import '../../components/packages/packages.scss';
import '../../components/packages/packages';
import '../../components/three-steps/three-steps.scss';
import '../../components/numbers/numbers.scss';
import '../../components/our-services/our-services.scss';
import '../../components/reviews/reviews.scss';
import '../../components/reviews/reviews';
import '../../components/faq_old/faq_old.scss';
import '../../components/faq_old/faq_old';
import '../../components/hero/hero';