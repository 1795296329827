
document.addEventListener('DOMContentLoaded', () => {
    let acc = document.getElementsByClassName("faq__accordion");
    for (let i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            let panel = this.nextElementSibling;
            panel.classList.toggle("active");
        });
    }
});