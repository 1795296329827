var dir         = document.location.origin;
var theme       = '/wp-content/themes/Craft-child/';
var lib         = 'inc/handlers/';
var ajaxPrepros = dir + theme + lib + 'action.php';

document.getElementById('uploaderSend').addEventListener('click', function(event) {
    event.preventDefault();

    var mail = document.querySelector('#uploader #email').value;
    var fileInput = document.querySelector('#uploader #file');
    var file = fileInput.files[0];

    var data = new FormData();
    Array.from(fileInput.files).forEach(function(file, i) {
        data.append('file', file);
    });
    console.log(mail);
    if (mail.length === 0 || !mail.includes('@')) {
        var response = 'Sorry, e-mail field is empty or invalid';

        document.getElementById('disclaimer').innerHTML = response;
        document.getElementById('uploaderOk').textContent = 'Let me try';
        $('#uploaderModal').modal({
            'show': true,
            'backdrop': 'static'
        });
        return;
    }
    data.append('email', mail);

    var xhr = new XMLHttpRequest();
    xhr.open('POST', ajaxPrepros, true);

    xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 400) {
            var data = parseInt(xhr.responseText);
            var response;
            var button = 'Let me try';

            var dis = document.getElementById('disclaimer');
            var upl = document.getElementById('uploaderOk');
            var fil = document.getElementById('file');
            var ema = document.getElementById('email');
            var sen = document.querySelector('#file + label');

            switch (data) {
                case 1:
                    dataLayer.push({'event': 'resume_upload'});
                    // var path = dir + '/thank-page/';
                    // document.location.href = path;
                    return false;
                case 2:
                    response = 'Please provide a smaller file (Less than 3Mb)';
                    fil.value = '';
                    sen.setAttribute('data-after', sen.getAttribute('data-default'));
                    break;
                case 3:
                    response = 'Please provide supported file type (doc/pdf)';
                    fil.value = '';
                    sen.setAttribute('data-after', sen.getAttribute('data-default'));
                    break;
                case 4:
                    response = 'Sorry, we already have that e‑mail <span>' + mail + '</span>';
                    button = 'Let me try another mail';
                    ema.value = '';
                    break;
                case 5:
                    response = 'Sorry, e-mail field is empty or invalid';
                    ema.value = '';
                    break;
                default:
                    response = 'Unassigned error';
            }

            dis.innerHTML = response;
            upl.textContent = button;


            let newModal = document.getElementById('modal-ok');
            newModal.classList.add('active');

            document.querySelector('.hero-uploader__cancel').classList.remove('active');

            var emailUploader = document.getElementById('emailUploader');
            emailUploader.style.transition = 'all 0.4s linear';
            emailUploader.style.opacity = '0';
            setTimeout(function() {
                emailUploader.style.display = 'none';
            }, 400);

            document.getElementById('file').value = '';

            var customFileLabel = document.querySelector('.custom-file-label');
            customFileLabel.classList.remove('selected');
            customFileLabel.setAttribute('data-after', 'Upload file');


        } else if (xhr.status == 413) {
            var response = 'Please provide a smaller file';

            document.getElementById('disclaimer').innerHTML = response;
            document.getElementById('uploaderOk').textContent = 'Let me try';

            // $('#uploaderModal').modal({
            //     'show': true,
            //     'backdrop': 'static'
            // });

            let newModal = document.getElementById('modal-ok');
            newModal.classList.add('active');
        }
    };

    xhr.onerror = function() {
        console.error('An error occurred during the request.');
    };

    xhr.send(data);
});

document.querySelectorAll('.block-banner-type-close').forEach(function(closeBtn) {
    closeBtn.addEventListener('click', function() {
        this.parentElement.remove();
    });
});

document.querySelectorAll('.new-pop-close').forEach(function(closeBtn) {
    closeBtn.addEventListener('click', function() {
        let newModal = document.getElementById('modal-ok');
        newModal.classList.remove('active');
    });
});
