document.addEventListener('DOMContentLoaded', () => {
    let whyChooseFlex = document.querySelector('.why-choose__flex');
    let whyChooseMore = document.querySelector('.why-choose__more');
    let whyChooseBox = document.querySelector('.why-choose__box');
    let fullHeight = whyChooseFlex.scrollHeight;

    function adjustHeight() {
        if (whyChooseBox.style.display !== 'none') {
            if (window.innerWidth < 630) {
                let halfHeight = fullHeight / 2;
                whyChooseFlex.style.height = halfHeight + 'px';
            } else {
                whyChooseFlex.style.height = 'auto';
            }
        }
    }

    window.addEventListener('resize', adjustHeight);
    adjustHeight();

    whyChooseMore.addEventListener('click', function () {
        whyChooseFlex.style.height = fullHeight + 'px';
        whyChooseMore.style.display = 'none';
        whyChooseBox.style.display = 'none';
    });

});