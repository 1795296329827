
document.addEventListener('DOMContentLoaded', () => {

    const tabs = document.querySelectorAll('.packages__tab');
    const contents = document.querySelectorAll('.packages__show');

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
            tabs.forEach(tab => tab.classList.remove('active'));
            contents.forEach(content => content.classList.remove('active'));

            tab.classList.add('active');
            contents[index].classList.add('active');
        });
    });
});